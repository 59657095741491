<script setup lang="ts">
const { locales } = useI18n();
const switchLocalePath = useSwitchLocalePath();
</script>

<template>
  <div class="locale-switcher">
    <template v-for="(locale, idx) in locales" :key="locale.code">
      <NuxtLink :to="switchLocalePath(locale.code)">
        {{ locale.code }}
      </NuxtLink>
      <span v-if="locales.length - 1 !== idx">/</span>
    </template>
  </div>
</template>
