<template>
  <div id="navigation-menu">
    <NuxtLink :to="{ path: localPath('/'), query: { projects: 'true' } }">{{
      t("projects")
    }}</NuxtLink>
    <template
      v-if="strapiPages?.data"
      v-for="(page, idx) in strapiPages?.data"
      :key="page.attributes?.title + '_' + page.id + '_' + idx"
    >
      <span>-</span>
      <NuxtLink :to="localPath(`/${page.attributes?.slug}`)">{{
        page.attributes?.title
      }}</NuxtLink>
    </template>

    <LanguageSwitcher />
  </div>
</template>

<script setup lang="ts">
// Types
import type { PageEntityResponseCollection } from "@/types/Strapi";

// i18n / Language
const { t } = useI18n();
const localPath = useLocalePath();

const props = defineProps({
  strapiPages: {
    type: Object as PropType<PageEntityResponseCollection>,
  },
});
</script>
